export const communitiesName = {
    "mozambique": '74414f75fe4717e3ff858bbdfa0bb5fc',
    'youthwyze':"708dfa7b288b87ef2b1f2ee562c9d89c",
    'gnp':'04f3a1d75961dc94d6cf2a32744aacc9',
    'atmkenya':'fee5c0c217af285485abb2e583cb806c',
    'ncdpak':'0af5b9b6bd80899082419998cb3bf18b',
    'romania' : 'efee0129b8337c0c2fb544e7ca8c5830',
    'activityTrackerPaylod':{
        'romania' : 'efee0129b8337c0c2fb544e7ca8c5830',
    }
    
}