import React from 'react';
import { Alert } from 'reactstrap';
import { Container, Row, Col, Button } from 'react-bootstrap';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError = error => {
    return { hasError: true };
  };

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }

  logout = () => {
    localStorage.removeItem('community');
    let url = `${window.location.origin}/`
    window.location.replace(url);
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Container>
          <Row>
            <Col sm="12" className="my-3">
              <Alert color="warning" className="text-center">
                Something went wrong. Please Contact your Administrator.
              </Alert>
            </Col>
            <Col className="text-right">
              <Button variant="danger" onClick={this.logout}>Re-login</Button>
            </Col>
          </Row>
        </Container>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;